<template>
    <!-- https://i.imgur.com/5Uru26k.png -->
    <div class="dropdown-select" :class="classes">
        <div class="dropdown-select__wrapper" :class="invalid ? 'invalid' : ''">
            <ul class="dropdown-select__items" :class="invalid ? 'invalid' : ''">
                <li v-for="(option, key) in options" :key="key" class="dropdown-select__item">
                    <button
                        type="button"
                        class="dropdown-select__item-text"
                        :class="option.style ? option.style : 'text-gray-900'"
                        @click="() => emitChangeSelected(option)"
                    >
                        <p
                            :class="
                                selected &&
                                ((selected.title && selected.title === option.title) ||
                                    (selected.name && selected.name === option.name))
                                    ? 'text-site-primary'
                                    : ''
                            "
                        >
                            {{ option.title || option.name }}
                        </p>
                    </button>
                </li>
            </ul>
        </div>
    </div>
</template>

<script lang="ts" setup>
import type { PropType } from 'vue'

type TDataOptions = {
    id?: number
    name?: string
    title?: string
    selected?: string
    icon?: string
    style?: string
}

const props = defineProps({
    options: {
        type: Object as PropType<TDataOptions[]>,
        default: () => {},
    },
    selected: {
        type: Object as PropType<TDataOptions>,
        default: () => {},
    },
    classes: {
        type: String,
        default: '',
    },
    invalid: {
        type: [Boolean, String],
    },
    kindData: {
        type: String,
    },
    handleClose: {
        type: Function,
    },
})

const emits = defineEmits(['newSelected'])

const emitChangeSelected = (newSelected: any) => {
    emits('newSelected', newSelected)
}
</script>

<style lang="postcss" scoped>
.dropdown-select {
    @apply absolute top-[100%] z-50;

    &__wrapper {
        @apply relative rounded-2xl border border-gray-200 bg-white p-2 shadow-2xl;
    }

    &__items {
        @apply max-h-48 overflow-auto;
        &::-webkit-scrollbar {
            width: 3px; /* width of the entire scrollbar */
        }
        &::-webkit-scrollbar-track {
            background: #f8f7fc; /* color of the tracking area */
        }
        &::-webkit-scrollbar-thumb {
            background-color: #741fa2; /* color of the scroll thumb */
            border-radius: 10px; /* roundness of the scroll thumb */
        }
    }

    &__item {
        @apply flex w-full items-center space-x-2 rounded p-1 text-gray-800 transition-colors hover:text-site-primary lg:hover:bg-gray-50;
        img {
            @apply h-5;
        }
        &-text {
            @apply w-full items-start justify-start text-left text-base;
            p {
                @apply font-sans;
            }
        }
    }
}
</style>
